import { createContext, useState } from "react"

import ContextConsumer from "./ContextConsumer";

export const AuthContext = createContext(null)

export default function ContextOwner() {
    const [userName, setUserName] = useState(null);
    return <AuthContext.Provider value={userName}>
        <label htmlFor="userName">Username <input id="userName" onChange={e => setUserName(e.target.value)} /></label>
        <ContextConsumer />
    </AuthContext.Provider>
}