import React from 'react';
import { Link, NavLink, Routes, Route, BrowserRouter } from 'react-router-dom';

import Girl from "./components/Girl";
import Bind from "./components/Bind";
import ContextOwner from './components/ContextOwner';
import Jung16Assoc from './components/Jung16Assoc';
import Redux from './components/Redux';

// import PropTypes from 'prop-types';

import {
  AppBar, Box, Container, CssBaseline, Divider, Drawer, Grid2, List,
  ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Toolbar,
  Typography
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Code, Explicit, Home as HomeIcon, ListAlt, OpenInNew } from '@mui/icons-material';


function Home() {
  return <Container>
    <Grid2 container spacing={5}>
      <Grid2 item xs={3}>
        <Paper elevation={3} square>
          Hello
        </Paper>
      </Grid2>
      <Paper elevation={3} square>
        World
      </Paper>
    </Grid2>
  </Container>
}

function ListItemNavLink(props) {
  const { icon, primary, to, target } = props;

  return (
    <ListItem>
      <ListItemButton component={target !== undefined ? Link : NavLink} to={to} target={target}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItemButton>
    </ListItem>
  );
}

// ListItemNavLink.propTypes = {
//   icon: PropTypes.element,
//   primary: PropTypes.string.isRequired,
//   to: PropTypes.string.isRequired,
// };

function Menu() {
  return (
    <List>
      <ListItemNavLink to="/" primary="Home" icon={<HomeIcon />} />
      <ListItemNavLink to="/awards" primary="Awards" icon={<Explicit />} />
      <ListItemNavLink to="/context" primary="Context playground" icon={<Code />} />
      <ListItemNavLink to="/todo" primary="Redux-persist" icon={<ListAlt />} />
      <Divider />
      <ListItemNavLink to="/admin/" primary="Admin" target="_blank" icon={<OpenInNew />} />
      <ListItemNavLink to="/api/" primary="API" target="_blank" icon={<OpenInNew />} />
    </List >
  );
}

function App() {
  const drawerWidth = 240;
  const theme = createTheme({
    palette: {
      primary: {
        main: '#7ac',
      },
    },
  });

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <AppBar sx={{ zIndex: theme.zIndex.drawer + 1 }} position="fixed">
            <Toolbar>
              <Typography variant="h6">MUI playground</Typography>
            </Toolbar>
          </AppBar>
          <Drawer variant="permanent" sx={{ width: drawerWidth, flexShrink: 0, [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' } }}>
            <Toolbar />
            <Menu />
          </Drawer>
          <Box component="main" sx={{ flexGrow: 1, p: 3, width: `calc(100% - ${drawerWidth}px)` }}>
            <Toolbar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/awards" element={<Girl />} />
              <Route path="/bind" element={<Bind />} />
              <Route path="/context" element={<ContextOwner />} />
              <Route path="/jung" element={<Jung16Assoc />} />
              <Route path="/todo" element={<Redux />} />
            </Routes>
          </Box>
        </Box>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
