import { useState } from "react";

function Bind() {
    const [name, setName] = useState();
    return <>
        <label htmlFor="name">Name:</label>
        <input id="name" value={name} onChange={(e) => setName(e.target.value)} />
        <p>Variable value: "{name}"</p>
        <button onClick={() => setName('Max')}>Set variable to "Max"</button>
    </>

}

export default Bind;
