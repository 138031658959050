import { useState } from 'react';
import { connect } from 'react-redux';

const TodoList = ({ todos, addTodo, deleteTodo, setTodoFlag }) => {
  const [taskName, setTaskName] = useState("")
  return (
    <div>
      <h2>To Do list</h2>
      <ul>
        {todos.map((todo, index) => (
          <li key={index}>
            <button onClick={() => setTodoFlag(index, { important: !todo.important })}>!</button>
            <input type="checkbox" checked={todo.done ? true : false} onChange={() => setTodoFlag(index, { done: !todo.done })} />
            <span style={{
              ...todo.important ? { fontWeight: "bold" } : {},
              ...todo.done ? { textDecorationLine: 'line-through' } : {}
            }}> {todo.text} </span>
            <button onClick={() => deleteTodo(index)}>del</button>
          </li>
        ))}
        <li>
          <form onSubmit={(e) => { e.preventDefault(); addTodo(taskName); setTaskName(""); }}>
            <input autoFocus onChange={(e) => setTaskName(e.target.value)} value={taskName} />
            <button type="submit" disabled={taskName === ""} >Add</button>
          </form>
        </li>
      </ul>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    todos: state.tasklist.todos
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addTodo: (text) => dispatch({ type: 'ADD_TODO', text }),
    deleteTodo: (index) => dispatch({ type: 'DELETE_TODO', index }),
    setTodoFlag: (index, flag) => dispatch({ type: 'SET_TODO_FLAG', index, flag })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TodoList);
