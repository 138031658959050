import { configureStore } from '@reduxjs/toolkit';
import {
  persistStore, persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const initialState = {
  todos: []
};

const todoReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_TODO':
      return {
        ...state,  // Копируем все поля состояния
        todos: [...state.todos, { text: action.text }] // Добавляем новый элемент в массив todos
      };
    case 'DELETE_TODO':
      return {
        ...state,
        todos: state.todos.filter((_, index) => index !== action.index)
      };
    case 'SET_TODO_FLAG':
      return {
        ...state,
        todos: state.todos.map((todo, index) => index === action.index ? { ...todo, ...action.flag } : todo)
      }
    default:
      return state;
  }
};

const persistConfig = {
  key: 'state',
  storage,
};

const persistedReducer = persistReducer(persistConfig, todoReducer);

const store = configureStore({
  reducer: {
    tasklist: persistedReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  devTools: true
});

const persistor = persistStore(store);

export { store, persistor };
