import { useState } from "react";

function GirlUpload({ reloadGirl }) {
    const [name, setName] = useState();
    const [years, setYears] = useState()
    const [photo1, setPhoto1] = useState();
    const [photo2, setPhoto2] = useState();
    const [photo3, setPhoto3] = useState();
    const setters = {
        photo1: setPhoto1,
        photo2: setPhoto2,
        photo3: setPhoto3
    }
    function uploadFile(event) {
        if (event.target.files) {
            setters[event.target.id](event.target.files[0]);
        }
    }

    function getCookie(key) {
        var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
        return b ? b.pop() : "";
    }

    function sendForm(event) {
        event.preventDefault();
        const formData = new FormData();
        formData.append('name', name)
        formData.append('years', years)
        formData.append('photo_1', photo1)
        formData.append('photo_2', photo2)
        formData.append('photo_3', photo3)
        fetch('/api/girls/', {
            method: 'POST',
            headers: {
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: formData
        })
            .then((resp) => {
                if (resp.status === 201) {
                    event.target.reset()
                    reloadGirl(name)
                }
            })
            .catch((e) => console.error(e))
    }

    return <form className="girl-form" onSubmit={sendForm}>
        <h2>Add new awardee</h2>
        <label htmlFor="girl_name">Awardee name</label>
        <input id="girl_name" onChange={e => setName(e.target.value)} />
        <label htmlFor="girl_years">Awards years</label>
        <input id="girl_years" onChange={e => setYears(e.target.value)} />
        <label htmlFor="girl_photo1">First photo</label>
        <input id="photo1" type="file" onChange={uploadFile} />
        <label htmlFor="girl_photo2">Second photo</label>
        <input id="photo2" type="file" onChange={uploadFile} />
        <label htmlFor="girl_photo3">Third photo</label>
        <input id="photo3" type="file" onChange={uploadFile} />
        <button>Upload</button>
    </form>
}

export default GirlUpload;
