import { useState, useEffect } from "react";

import { ImageList, ImageListItem } from "@mui/material";

import GirlUpload from "./GirlUpload";

import "./Girl.css";

function GirlInfo({ girl }) {
    if (girl === undefined) {
        return <></>
    }
    return <>
        <h2>{girl.name}</h2>
        <h3>AWN Female Performer of the Years: {girl.years}</h3>
        <ImageList cols={3} rowHeight={200}>
            <ImageListItem>
                <img src={girl.photo_1} alt="img_1" loading="lazy" />
            </ImageListItem>
            <ImageListItem>
                <img src={girl.photo_2} alt="img_2" loading="lazy" />
            </ImageListItem>
            <ImageListItem>
                <img src={girl.photo_3} alt="img_3" loading="lazy" />
            </ImageListItem>
        </ImageList>
    </>
}

function Girl() {
    const [girlsData, setGirlsData] = useState([]);
    const [girlName, setGirlName] = useState("");
    const [isFormVisible, setFormVisible] = useState(false);
    function showGirl(event) {
        setGirlName(event.target.value)
    }
    function showForm(event) {
        setFormVisible(prevState => !prevState)
    }
    function reloadGirl(name = "") {
        fetch('/api/girls/')
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                if (data.length > 0) {
                    setGirlsData(data);
                    setGirlName(data[0].name)
                } else {
                    setGirlsData([]);
                    setGirlName("");
                }
                setFormVisible(false);
                if (name !== "") {
                    setGirlName(name)
                }
            })
            .catch((e) => { console.error(e); setGirlsData([]) })
    }
    useEffect(() => {
        reloadGirl()
    }, [])
    const girl = girlsData.find(i => i.name === girlName);
    return <>
        <button id="addGirl" onClick={showForm} key='addGirl' >{isFormVisible ? "-" : "+"}</ button>
        {isFormVisible ? <GirlUpload key='form' reloadGirl={reloadGirl} /> : <>
            <select onChange={showGirl} value={girlName} key='select'>{girlsData.map(i => <option key={i.name} value={i.name}>{i.name}: {i.years}</option>)}</select>
            <GirlInfo girl={girl} />
        </>}
    </>
}

export default Girl;