import { useState } from "react"

function Description() {
    return <>
        <h2>Описание</h2>
        <p>Метод заключается в изучении ассоциаций, связанных с вашей проблемой. Последовательность действий:</p>
        <ul>
            <li>Формулируете тему</li>
            <li>Выписываете 16 ассоциаций на неё, не сильно задумываясь. Это первый уровень ассоциаций.</li>
            <li>Берёте попарно выписанные ассоциации, и выписываете на каждую пару одну новую ассоциацию. Это второй уровень, на нём будет 8 ассоциаций.</li>
            <li>Аналогично берёте попарно ассоциации второго уровня и получаете на них 4 ассоциации третьего уровня.</li>
            <li>Таким же образом получаете 2 ассоциации четвёртого уровня и ассоциацию пятого уровня.</li>
            <li>Ассоциация пятого уровня является ключом, который может помочь решить проблему. Решение может придти не сразу, а в течение пары недель.</li>
        </ul>
    </>
}
function Problem({ assoc, setAssoc }) {
    if (assoc.topic) {
        return <p>Topic: {assoc.topic}</p>
    } else {
        return <>
            <form onSubmit={formData => setAssoc({ topic: formData.get("topic") })} >
                <label htmlFor="topic">Тема: </label>
                <input name="topic" />
                <input type="button" name="topicSubmit" value="Submit" />
            </form>
        </>
    }
}
export default function Jung16Assoc() {
    const [assoc, setAssoc] = useState(localStorage.getItem('jung16assoc') || {});
    return <>
        <Description />
        <Problem assoc={assoc} setAssoc={setAssoc} />
    </>
}
